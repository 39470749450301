<template lang="pug">
div
  template(v-for="tag in value")
    v-chip.ma-1.white--text(
      color="light-blue"
      outlined
    )
      | {{tag.name}}
      v-icon(
        @click="modalRemove = true, tagId = tag.id"
        small
        right
      ) fa-times

  v-tooltip(right)
    template(v-slot:activator="{ on }")
      v-btn(
        v-on="on"
        fab
        small
        variant="light"
        prependIcon="fa-plus"
        @click="modalAdd = true"
        elevation="0"
      )
        v-icon(
          color="blue"
          small
        ) fa-plus
    span Добавить тэг

  v-dialog(
      v-model="modalAdd"
      max-width="600"
    )
      v-card
        v-card-title Добавить к теминалу тэг.
        v-card-text
          v-row
            v-col(cols="12")
              v-text-field(
                v-model="name"
                label="Тэг"
                outlined
                dense
              )
          v-row
            v-col(cols="6")
              v-btn(
                color="blue"
                @click="modalAdd = false"
                :disabled="$root.isDisabled('tags')"
                outlined
                block
              ) Отмена
            v-col(cols="6")
              v-btn.white--text(
                color="blue"
                :loading="$root.isLoading('tags')"
                :disabled="$root.isDisabled('tags')"
                @click="add"
                block
              ) Добавить
  v-dialog(
      v-model="modalRemove"
      max-width="600"
    )
      v-card
        v-card-title Удалить тэг?
        v-card-text
          v-row
            v-col(cols="6")
              v-btn(
                @click="modalRemove = false"
                :disabled="$root.isDisabled('tags')"
                themes="mp"
                outlined
                color="blue"
                block
              ) Отмена
            v-col(cols="6")
              v-btn.white--text(
                color="blue"
                :loading="$root.isLoading('tags')"
                :disabled="$root.isDisabled('tags')"
                @click="remove"
                block
              ) Удалить
</template>

<script>
import api from '@/api';

export default {
  name: 'TagsControls',

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    terminalId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      modalAdd: false,
      modalRemove: false,
      name: null,
      tagId: null,
    };
  },

  methods: {
    async add() {
      try {
        // const { data } = await api('terminals/tags').add(this.terminalId, {
        //   name: this.name,
        // });

        // FIXME: no props mutating
        // this.value.push(data);
        this.name = null;

        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Тэг добавлен.',
          text: '',
        });
      } catch (e) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Произошла ошибка при добалении тэга.`,
          text: '',
        });
      } finally {
        this.modalAdd = false;
      }
    },

    removeFromValue(id) {
      // FIXME: no props mutating
      // const ID = id;
      // let idx = null;
      // this.value.forEach((item, index) => {
      //   if (item.id === ID) {
      //     idx = index;
      //   }
      // });
      // this.value.splice(idx, 1);
    },

    async remove() {
      try {
        await api('terminals/tags').delete(this.terminalId, this.tagId);
        this.removeFromValue(this.tagId);

        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Тэг удален.',
          text: '',
        });
      } catch (errors) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Произошла ошибка при удалении тэга.`,
          text: '',
        });
      } finally {
        this.modalRemove = false;
      }
    },
  },
};
</script>
